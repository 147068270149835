import * as React from "react"
import { Layout } from "../../../components/layout"
import { Seo } from "../../../components/seo"
import { Container } from "react-bootstrap"
import CollectionsList from "../../../components/CollectionsList"
import { graphql } from "gatsby"

export default function CategorieIndex({ data }) {
  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <h1 className="display-2 fw-bold text-center mb-4">Onze productcategorieën</h1>
        <CollectionsList collections={data?.allShopifyCollection?.nodes} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allShopifyCollection {
      nodes {
        ...CollectionCard
      }
    }
  }
`
export const Head = () => (
  <Seo
    title="Categorieën"
  />
)
